import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: ${({ legendWidth }) => legendWidth ? '62%' : '100%'};
  height: ${({ isHealthData }) => isHealthData && '100%'};
  .highcharts-container {
    height: 100% !important;
    width: 100% !important;
    > svg {
      height: 100%;
      width: 100%;
    }
    .highcharts-point {
      width: 13px;
      height: 13px;
      border-radius: 6px;
    }
  }
`;


export { Container };