import styled from 'styled-components';

const Container = styled.div`
  width: 270px;
  height: 230px;
  margin-left: -35px;
  .highcharts-container {
    height: 100% !important;
    width: 100% !important;
    > svg {
      height: 100%;
      width: 100%;
    }
    .highcharts-point {
      width: 13px;
      height: 13px;
      border-radius: 6px;
    }
  }
  @media(max-width: 750px){
      margin-left: 0;
  }
  @media(max-width: 500px){
      width: 100%;
      height: 100%;
  }
`;


export { Container };