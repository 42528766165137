import React, { Component } from 'react';
import Highcharts from 'highcharts';
import PropTypes from 'prop-types';

import { Container } from './styles';

class DonutChart extends Component {
  componentDidMount() {
    const { data, container, legendWidth} = this.props;
    if(data && container) {
      this.renderGraph(data, container, legendWidth);
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props !== prevProps) {
      this.renderGraph(this.props.data, this.props.container, this.props.legendWidth);
    }
  }

  renderGraph = (data, container, legendWidth, /*hideLegend*/) => new Highcharts.Chart({
    chart: {
      type: 'pie',
      renderTo: container,
      spacingTop: 0,
      spacingLeft: 0,
      spacingBottom: 0,
      spacingRight:0,
      margin: [0,0,0,0],
    },
    title:{
      text: null
    },
    credits: {
      enabled: false
    },
    tooltip: {
      backgroundColor: '',
      borderWidth: 0,
      useHTML: true,
      formatter() {
        return `
                  <div class="donut-chart-tooltip">
                      <span class="point-percentage">${this.y}%</span>
                  </div>
              `;
      }
    },
    legend: {
      enabled: legendWidth ? false : true,
      layout: 'horizontal',
      align: legendWidth ? 'center' : 'right',
      verticalAlign: legendWidth ? 'bottom' : 'middle',
      width:  legendWidth ? '' : 165,
      itemMarginTop: 20,
      labelFormat: '{name}',
      itemStyle: {
        color: '#999999',
        fontSize: '10px',
        fontWeight: 'normal',
        fill: '#999999',
        font: 'Rubik'
      }
    },
    plotOptions: {
      pie: {
        dataLabels: {
          showInLegend: true,
          enabled: true,
          color: 'white',
          distance: -20,
          shadow: false,
          formatter: function() {
            let s = this.y !== 0 ? this.y+'%' : '';
            return s;
          },
          style: {
            fontSize: '12px',
            color: 'white',
            fontWeight: 'lighter',
            textOutline: 'none',
            fontFamily: 'Rubik-Regular',
            visibility: 'hidden'
          }
        },
        animation: false
      }
    },
    series: [{
      name: 'Versions',
      data: data,
      size: '80%',
      innerSize: '50%',
      innerColor: '#ffffff',
      id: 'versions',
      showInLegend: true,
    }],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 330
        },
        chartOptions: {
          plotOptions: {
            pie: {
              dataLabels: {
                distance: -15,
                style: {
                  fontSize: '10px',
                  display: 'none'
                }
              }
            }
          },
          legend: {
            width: 100,
            itemStyle: {
              color: 'rgb(153,153,153)',
              fontSize: '10px'
            }
          }
        }
      }]
    }
  });

  render() {
    return (
      <Container id={this.props.container} legendWidth={this.props.legendWidth}/>
    )
  }
}

DonutChart.propTypes = {
  data: PropTypes.array.isRequired,
  container: PropTypes.string.isRequired,
  legendWidth: PropTypes.number
};

export default DonutChart;
