import React, { Component } from 'react';
import Highcharts from "highcharts";
import PropTypes from 'prop-types';
// eslint-disable-next-line no-undef
require('highcharts/highcharts-more')(Highcharts);
// eslint-disable-next-line no-undef
require('highcharts/modules/solid-gauge')(Highcharts);

import { Container } from './styles';

class SolidGauge extends Component {

  componentDidMount() {
    const { container, color, y } = this.props;
    new Highcharts.chart({
      chart: {
        type: 'solidgauge',
        renderTo: container,
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0)'
      },

      title: null,

      credits: {
        enabled: false
      },
      tooltip: {
        backgroundColor: '',
        borderWidth: 0,
        useHTML: true,
        formatter() {
          return `
                    <section class="donut-chart-tooltip">
                        <span class="point-percentage">${this.y}%</span>
                    </section>
                `;
        }
      },
      pane: {
        startAngle: 180,
        background: [{
          outerRadius: '120%',
          innerRadius: '80%',
          backgroundColor: Highcharts.Color(Highcharts.getOptions().colors[0])
            .setOpacity(0.3)
            .get(),
          borderWidth: 0,
          shape: "arc"
        }],
      },

      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: true,
            format: '{y}%'
          },
          stickyTracking: false,
          rounded: false
        }
      },

      series: [{
        name: 'Move',
        data: [{
          color: color,
          radius: '120%',
          innerRadius: '80%',
          y: y
        }],
      }]
    });
  }

  render() {
    return (
      <Container id={this.props.container} Triglycerides/>
    )
  }
}

SolidGauge.propTypes = {
  container: PropTypes.string.isRequired,
  legendWidth: PropTypes.number,
  color: PropTypes.string.isRequired,
  y: PropTypes.number.isRequired
};

export default SolidGauge;
